import { Container } from '@finn/atoms';
import { Features as Experiments, useIsABVariant } from '@finn/ua-featureflags';
import { Box, Hidden, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

import RawHtml from '~/components/RawHtml';

import CTAs from './CTAs';
import Features from './Features';
import ImageBlock from './ImageBlock';
import { Hero2CosmicMetadata } from './metaData';
import Stats from './Stats';

type Props = {
  data: Hero2CosmicMetadata;
  onSecondaryCTAClick?: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  outerContainer: {
    width: '100%',
    maxWidth: '2100px',
    margin: '0 auto',
    overflow: 'hidden',
  },
  gridContainer: {
    display: 'grid',
    gridGap: theme.spacing(4),
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('md')]: {
      gridGap: theme.spacing(6),
      gridTemplateColumns: '440px 1fr',
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '560px 1fr',
    },
  },
  textBlock: {
    [theme.breakpoints.up('md')]: {
      gridColumn: '1/2',
    },
    placeSelf: 'center',
    width: '100%',
    '& h1 span': {
      display: 'block',
    },
    margin: theme.spacing(4, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(6),
    },
  },
  description: {
    marginBottom: theme.spacing(3),
  },
}));

const Hero2: React.FC<Props> = ({ data, onSecondaryCTAClick = () => {} }) => {
  const classes = useStyles();
  const isExpHPHeroSwap = useIsABVariant(Experiments.ExpHPHeroSwap);
  if (!data) return null;
  const { label, title, description } = data;

  return (
    <Box data-testid="hero2" className={classes.outerContainer}>
      {isExpHPHeroSwap && (
        <Hidden mdUp implementation="css">
          <ImageBlock
            url={data.hero_image?.url}
            altText={data.hero_image_alt_text}
          />
        </Hidden>
      )}
      <Container leftOnly>
        <div className={classes.gridContainer}>
          <div className={classes.textBlock}>
            <h1
              className="mobile-t1-semibold md:web-t1-semibold mb-8"
              data-cy="hero-title"
            >
              {label && <span className="body-16-semibold mb-2">{label}</span>}
              <span>{title}</span>
            </h1>
            {description && (
              <div className={classes.description}>
                <RawHtml html={description} useGlobalStyles fullWidthMedia />
              </div>
            )}
            <Features features={data.features} />

            <CTAs
              primaryCTA={data.primary_cta}
              secondaryCTA={data.secondary_cta}
              onSecondaryCTAClick={onSecondaryCTAClick}
            />
            <Stats stats={data.stats} />
          </div>
          <Hidden smDown implementation="css">
            <ImageBlock
              url={data.hero_image?.url}
              altText={data.hero_image_alt_text}
            />
          </Hidden>
        </div>
      </Container>
      {!isExpHPHeroSwap && (
        <Hidden mdUp implementation="css">
          <ImageBlock
            url={data.hero_image?.url}
            altText={data.hero_image_alt_text}
          />
        </Hidden>
      )}
    </Box>
  );
};
export default Hero2;
